<template>

  <v-app id="app">

    <v-navigation-drawer 
      v-model="sidebar" 
      absolute
      left
      temporary
      class="drawer"
      >
  
      <v-list>
        <v-list-group
          v-for="item in menus"
          :key="item.category_name"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-action>
              <v-img
                :lazy-src="item.icon"
                max-height="20"
                max-width="20"
                :src="item.image"
                class=""
              />
            </v-list-item-action>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="caption font-weight-medium">{{ item.category_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in item.subcategories"
            :key="subItem.name"
            :to='{name:"productSubCategory",params:{name:subItem.name }}'
          >
            <v-list-item-content>
              <v-list-item-title class="caption">{{ subItem.name }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list-group>
      </v-list>

    </v-navigation-drawer>  

    <v-container>
      <v-row>
          <v-col cols="12" lg="12" xl="12" sm="12" class="text-uppercase text-center caption font-weight-bold" style="margin-top:0 !important;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;color:#171AB8;">
            Welcome to mco-shop. Your ultimate shop of your choice
          </v-col>
      </v-row>
    </v-container>

    <b-container fluid v-if="ads" class="mb-3 hidden-sm-and-down">
      <b-row>
          <b-col cols="12" lg="12" xl="12" sm="12" class="p-0 m-0" style="margin-top:0 !important;background:#171AB8;min-height:45px !important;background-position:center;background-repeat:no-repeat;background-size:contain;" :style="{'background-image':`url(${ads.image})`}">
          </b-col>
      </b-row>
    </b-container>

    <v-main class="grey lighten-4">
      <div class="shadownav" style="width:100% !important;background:#ffffff !important">
        <b-container class="white py-0" :class="{block: scrollPosition < 100, fixed: scrollPosition > 100}">
          <v-row class="hidden-sm-and-up">
            <v-col cols="12" class="d-flex justify-center align-center text-center" style="width:100%">
              <router-link class="flex justify-center" to="/">
                      <v-img
                        lazy-src="/assets/logo.png"
                        max-height="100%"
                        max-width="100%"
                        src="/assets/logo.png"
                        class="mr-3"
                      ></v-img>
              </router-link>
            </v-col>
          </v-row>
          <v-row class="my-0">
              <v-col cols="12" class="d-flex justify-space-around align-center" style="width:100%">
                  <div>
                    <span class="hidden-md-and-up mr-3">
                      <v-icon @click.stop="sidebar = !sidebar">menu</v-icon>
                    </span>
                    <router-link class="flex justify-center hidden-sm-and-down" to="/">
                      <v-img
                        lazy-src="/assets/logo.png"
                        max-height="100%"
                        max-width="100%"
                        src="/assets/logo.png"
                        class="mr-3"
                      ></v-img>
                    </router-link>
                  </div>

                  <ul class="exo-menu hidden-sm-and-down">
                    <li class="drop-down menu__toggle"><a href="javascript:void(0)"><v-icon>mdi-menu</v-icon> <span> Shop by Category</span></a>
                      <!--Drop Down-->
                      <ul class="drop-down-ul animated fadeIn listshadow">
                        <li class="flyout-right" v-for="item in menus" :key="item.category_name">
                          <a href="javascript:void(0)"> 
                            <v-img
                              :lazy-src="item.icon"
                              max-height="20"
                              max-width="20"
                              :src="item.image"
                              class="mr-3"
                            /> 
                          {{ item.category_name }}
                        </a><!--Flyout Right-->
                          <ul class="submenu animated fadeIn listshadow">
                            <li v-for="subItem in item.subcategories" :key="subItem.name"><router-link  :to='{name:"productSubCategory",params:{name:subItem.name }}'>{{ subItem.name }}</router-link></li>
                          </ul>
                        </li>
                      </ul>
                      <!--//End drop down-->
                    
                    </li>
                  </ul>

                  <div class="navsearch hidden-sm-and-down">
                    <form class="header-search hs-expanded mx-5 px-3 flex items-center">
                      <b-input-group
                      >
                        <b-form-input placeholder="Search products and categories"></b-form-input>

                        <b-input-group-append>
                          <b-button size="sm" text="Button" variant="default"><i class="fa fa-search"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>

                    </form>
                  </div>

                  <div class="navcart">
                    <span>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="120"
                        offset-y
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                            class="text-capitalize button"
                          >
                           <v-icon class="mx-2">person</v-icon> 
                           <span v-if="!isLogged">Account</span> 
                           <span v-else>
                             {{ $store.state.user.user.name }}
                           </span> 
                           <v-icon class="mx-2">expand_more</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>Member Account</v-list-item-title>
                                <v-list-item-subtitle>Account helps you track orders and checkout</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-divider></v-divider>

                          <v-list v-if="!isLogged">
                            <v-list-item>
                              <v-list-item-title>
                                <v-btn
                                  text
                                  color="black"
                                  class="text-capitalize button green lighten-2"
                                  @click.stop="dialog = true"
                                >
                                 Signin / Signup
                                </v-btn>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>

                          <v-list v-else>
                            <v-list-item>
                              <v-list-item-title>
                                <v-btn
                                  text
                                  color="black"
                                  class="text-capitalize button green lighten-2"
                                >
                                 Go to Profile
                                </v-btn>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              link
                              @click="logout()" 
                            >
                              <v-list-item-title style="padding:5px 5px !important;height: auto !important;margin:0 !important;font-size:15px;float:left !important">
                                <v-icon>mdi-logout-variant</v-icon>
                                <span style="padding-left:3% !important" @click="logout()">Logout</span>
                              </v-list-item-title>
                            </v-list-item>

                          </v-list>

                        </v-card>
                      </v-menu>
                    </span>
                    <span>
                      <v-btn
                        text
                        color="black"
                        class="text-capitalize button"
                        v-b-toggle.cartPreviewModal
                      >
                      <v-badge
                        color="green"
                        :content="`${cart.length}`"
                      >
                        <v-icon>shopping_cart</v-icon>
                      </v-badge>
                        <!-- <span class="mx-2">Cart</span> -->
                      </v-btn>
                    </span>
                  </div>

              </v-col>
          </v-row>
        </b-container> 
      </div>
      <v-container class="mainwrapper" style="margin:.5rem auto !important">
        <v-row>
            <v-col cols="12" sm="12" lg="12" xl="12">
              <progress-bar></progress-bar>
              <router-view></router-view>
            </v-col>
        </v-row>
      </v-container> 

      <b-sidebar
        id="cartPreviewModal"
        title="Shopping Cart"
        backdrop
        right 
        shadow
      >
        <div class="px-3 py-2" v-if="cart.length > 0">
          <div class="col-md-12 cartpreview py-3" v-for="(product, index) in cart" :key="index">
            <div style="display:flex !important;flex-direction:column !important;align-items: flex-start;">
              <h6 style="font-size:.85rem !important;font-weight:600">{{ product.item_name }}</h6>
              <span style="font-size:.8rem !important" class="my-2">{{ product.quantity }} x {{ (parseInt(product.price) - ((parseInt(product.price) * parseInt(product.offer)) / 100 )) | currency(' ') }}</span>
              <button class="removeItem" @click="removeItem(product)"><i class="fa fa-trash"></i></button>
            </div>
            <img alt="product" width="60" height="60" :data-src="product.image" :src="product.image" lazy="loaded" class="fade-in">
          </div>
          <div class="col-md-12 cartpreview py-1">
            <span style="font-size:1rem !important;font-weight:600 !important">Subtotal</span>
            <span style="font-size:1rem !important;font-weight:600 !important">{{ cart.reduce((acc, item) => acc + (parseInt(item.price) - (((parseInt(item.price) * parseInt(item.offer)) / 100 ) * item.quantity)),0) | currency(' ') }}</span>
          </div>
          <div class="pcart col-md-12 py-3 px-0 mt-2">
            <v-btn text class="btn btn-block btn-default mr-2" style="background:#E5E7EB;border:1px solid #E5E7EB;border-radius:4px" link to="/shopping-cart">View Cart</v-btn>
            <v-btn text class="btn btn-block btn-success" style="border-radius:4px;color:#fff !important" link to="/checkout" v-if="!isLogged">Checkout</v-btn>
            <v-btn text class="btn btn-block btn-success" link to="/product-payment" style="border-radius:4px;color:#fff !important" v-else>Checkout</v-btn>
          </div>
        </div>
        <div class="px-3 py-2" v-else>
          <div class="col-md-12 cartpreview">
            <p>No cart item found</p>
          </div>
        </div>
      </b-sidebar>

      <!-- payment popup -->
      <div class="paymentsuccess">
        <v-alert
          :value="toggleAlert"
          text
          outlined
          color="green"
          dismissible
          icon="mdi-fire"
        >
          <span v-html="alertMessage"></span>
        </v-alert>
      </div>

      <!-- registration form -->
      <v-dialog v-model="dialog" max-width="500px" min-width="360px">
          <div>
              <v-tabs v-model="tab" show-arrows background-color="deep-purple accent-4" icons-and-text dark grow>
                  <v-tabs-slider color="purple darken-4"></v-tabs-slider>
                  <v-tab v-for="(val,i) in tabs" :key="val.name">
                      <v-icon large>{{ val.icon }}</v-icon>
                      <div class="caption py-1">{{ val.name }}</div>
                  </v-tab>
                  <v-tab-item>
                      <v-card class="px-4">
                          <v-card-text>
                              <v-form ref="loginForm" v-model="valid" lazy-validation>
                                  <v-row>
                                      <v-col cols="12">
                                          <v-text-field v-model="loginEmail" :rules="loginEmailRules" label="E-mail" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field v-model="loginPassword" :append-icon="show1?'eye':'eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                                      </v-col>
                                      <v-col class="d-flex" cols="12" sm="6" xsm="12">
                                      </v-col>
                                      <v-spacer></v-spacer>
                                      <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                        <v-btn
                                            color="grey darken-1 mr-4"
                                            text
                                            @click="dialog = false"
                                          >
                                            Close
                                          </v-btn>
                                          <v-spacer></v-spacer>
                                          <v-btn text md block :disabled="!valid" :loading="loading" color="success" @click="validateLogin"> Login </v-btn>
                                      </v-col>
                                  </v-row>
                              </v-form>
                          </v-card-text>
                      </v-card>
                  </v-tab-item>
                  <v-tab-item>
                      <v-card class="px-4">
                          <v-card-text>
                              <v-form ref="registerForm" v-model="valid" lazy-validation>
                                  <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                          <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name" maxlength="20" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                          <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name" maxlength="20" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field v-model="mobile" :rules="[rules.required]" label="Mobile Number" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field block v-model="verify" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirm Password" counter @click:append="show1 = !show1"></v-text-field>
                                      </v-col>
                                      <v-spacer></v-spacer>
                                      <v-col class="d-flex" cols="12" sm="3" xsm="12">
                                        <v-btn
                                            color="grey darken-1 mr-4"
                                            text
                                            @click="dialog = false"
                                          >
                                            Close
                                          </v-btn>
                                          <v-spacer></v-spacer>
                                          <v-btn text md block :disabled="!valid" :loading="loading" color="success" @click="validate">Register</v-btn>
                                      </v-col>
                                  </v-row>
                              </v-form>
                          </v-card-text>
                      </v-card>
                  </v-tab-item>
              </v-tabs>
          </div>
      </v-dialog>

    </v-main>

    <Footer/>
    
  </v-app>
</template>
<style>
.paymentsuccess, .paymentsuccess .v-alert{
  position:fixed !important;
  top:1.5rem !important;
  right:2rem !important;
  z-index:9999 !important;
}
.paymentsuccess .v-alert{
  width:600px;
}
.paymentsuccess .v-alert--text {
     background: #ffffff !important; 
}
.v-list-item__subtitle, .v-list-item__title {
    white-space: normal !important;
}
</style>
<script>
import { mapGetters } from 'vuex'
import { Can } from '@casl/vue'
import Footer from '@/components/Footer'
import axios from 'axios'
import Pusher from 'pusher-js'
import ProgressBar from './components/ProgressBar.vue'

 export default {
  name: 'App',
  data: () => ({
      menu: false,
      sidebar: false,
      scrollPosition: null,
      toggleAlert: false,
      alertMessage: "",
      dialog: false,
      loading: false,
      tab: 0,
      tabs: [
          {name:"Login", icon:"mdi-account"},
          {name:"Register", icon:"mdi-account-outline"}
      ],
      valid: true,
      
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      verify: "",
      loginPassword: "",
      loginEmail: "",
      loginEmailRules: [
        v => !!v || "Required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      emailRules: [
        v => !!v || "Required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      show1: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 8) || "Min 8 characters"
      }
  }),
  components: {
    Can,Footer,ProgressBar
  },
  computed: {
    ...mapGetters([
      'isLogged'
    ]),
    menus: function() {
      return this.$store.state.categories
    },
    cart() {
      return this.$store?.state?.cart
    },
    promotions () {
        return this.$store.state.promotions
      },
      ads () {
        return this.$store.state.ads
      },

    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    }
      
  },
  mounted() {

    this.getDashboard()
    window.addEventListener('scroll', this.updateScroll);

    let user = null
    if(this.$store.state.user != null)
      user = this.$store.state.user.user.id

    var pusher = new Pusher('5cd0aebb1432e5841340', {
      cluster: 'ap2'
    });
    // invoices

    // add
    var channel = pusher.subscribe('payment.'+user);
    channel.bind('payment.'+user, data => {
        if(data.data.status == "success") {
          this.alertMessage = data.data.message
          this.toggleAlert = true
          setTimeout(()=>{
            this.toggleAlert=false
          },10000)
          
        }
    })
  
    
  },
  serverPrefetch () {
    return this.getDashboard()
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {

    validate() {
      if (this.$refs.registerForm.validate()) {
        this.loading = true
            this.disabled = true
            
            this.$store
            .dispatch('signup', {
                fname: this.firstName,
                lname: this.lastName,
                email: this.email,
                mobile: this.mobile,
                password: this.password
            })
            .then(() => {

                this.loading = false
                this.disabled = false

              if(this.$store.state.user.status === parseInt(200))
              {

                this.resetSignup()
                this.dialog = false

                this.$toast.success('Account creation was successful','Success',{position: 'topRight',timeout: 7000,})
                

              }else if(this.$store.state.user.status === parseInt(409))
              {

                this.resetSignup()

                this.$toast.error('The email used already exists. Use a different email or login','Error',{position: 'topRight',timeout: 7000,})
                
              }else{
                this.$toast.error('Check your credentials and try again','Error',{position: 'topRight',timeout: 7000,})
              }
            })
            .catch(error => {
                this.loading = false
                 this.disabled = false
                 this.$toast.error('Check your credentials and try again','Error',{position: 'topRight',timeout: 7000,})
            })
      }
    },
    validateLogin() {
      if (this.$refs.loginForm.validate()) {
      
        this.loading = true
        this.disabled = true

        this.$store
        .dispatch('login', {
          email: this.loginEmail,
          password: this.loginPassword
        })
        .then(() => {

          this.loading = false
          this.disabled = false

          if(this.$store.state.user.status === parseInt(200))
          {

            this.reset()
            this.dialog = false

            this.$toast.success('Login was successful','Success',{position: 'topRight',timeout: 7000,})
            
            // this.$router.push({ name: 'Payment' })

          }else{
            this.$toast.error('Credentials does not match','Error',{position: 'topRight',timeout: 7000,})
          }
        })
        .catch(() => {

          this.loading = false
          this.disabled = false

          this.$toast.error('Confirm details and try again','Error',{position: 'topRight',timeout: 7000,})
        })

      }
    },
    resetSignup() {
      this.$refs.registerForm.reset();
    },
    reset() {
      this.$refs.loginForm.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    removeItem(item) {
      this.$store.dispatch('removeItem', item)
    },
    logout () {
      this.$store.dispatch('logout')
    },
    async getDashboard(){
        this.$store
          .dispatch('getDashboard')
          .then(() => {
          })
          .catch(() => {
          })
      },
  }
};
</script>
