import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vuex from 'vuex'
import { store } from './store/index'
import vuetify from './plugins/vuetify'
import Vue2Filters from 'vue2-filters'
import axios from 'axios'
import VueSlimScroll from 'vue-slimscroll'
import "vue-select/dist/vue-select.css"
import VueToastr2 from 'vue-toastr-2'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta'
import LoadScript from 'vue-plugin-load-script'
import moment from 'moment'
import VueHead from 'vue-head'
import { abilitiesPlugin } from '@casl/vue';
import {ability} from './services/ability';
import { Can } from '@casl/vue'
import RangeDatePicker from 'vue-easy-range-date-picker'
import 'vuetify/dist/vuetify.min.css'
import 'izitoast/dist/css/iziToast.min.css'
import VueIziToast from 'vue-izitoast'
import VueGoogleCharts from 'vue-google-charts'
import VueBarcodeScanner from 'vue-barcode-scanner'
import VueHtmlToPaper from 'vue-html-to-paper'
import HighchartsVue from 'highcharts-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// custom css
import '@/assets/css/main.css'
import '@/assets/css/touchcss.css'
import '@/assets/css/responsive.css'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Slick from 'vue-slick'

import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

Vue.use(Slick)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


Vue.use(HighchartsVue)


const paperoptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    '/print.css'
  ],
  timeout: 0, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: "M-SHOP", // override the window title
}

Vue.use(VueHtmlToPaper, paperoptions);

let options = {
  sound: false, // default is false
  // soundSrc: '/static/sound.wav', // default is blank
  sensitivity: 100, // default is 100
  requiredAttr: false, // default is false
  // controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  // callbackAfterTimeout: true // default is false
}
 
Vue.use(VueBarcodeScanner, options)

Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })
Vue.use(VueHead)
Vue.use(RangeDatePicker)

Vue.config.productionTip = false


Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.prototype.moment = moment
Vue.filter('dateNo', function(value) {
    if (value) {
        return moment(String(value)).format('D')
    }
})
Vue.filter('dateName', function(value) {
    if (value) {
        return moment(String(value)).format('ddd')
    }
})
Vue.filter('dateF', function(value) {
    if (value) {
        return moment(String(value)).format("MMM Do, YYYY")
    }
})
Vue.filter('timeF', function(value) {
    if (value) {
        return moment(String(value)).format('LT')
    }
})
Vue.filter('timeremaining', function(value) {
    if (value) {
        return moment(String(value)).fromNow()
    }
})
Vue.filter('minutesremaining', function(value) {
    if (value) {
      var nowtime = moment()
      value = moment(String(value))

      var duration = moment.duration(value.diff(nowtime))
      var minutes = parseInt(duration.asMinutes())
        return minutes
        // return moment(String(value)).fromNow()
    }
})


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8",
    libraries: ["places", "geometry"]
  }
})
Vue.use(VueGoogleCharts)
Vue.use(LoadScript)
Vue.use(Notifications)
Vue.use(Vuex)
Vue.use(Vue2Filters)
Vue.use(VueSlimScroll)
Vue.use(VueIziToast)

new Vue({
  router,
  store,
  vuetify,
   created () {
    // const userInfo = localStorage.getItem('user')
    // if (userInfo) {
    //   const userData = JSON.parse(userInfo)
    //   this.$store.commit('setUserData', userData)
    // }
    // axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response.status === 401) {
    //       this.$store.dispatch('logout')
    //     }
    //     return Promise.reject(error)
    //   }
    // )
  },
  render: h => h(App)
}).$mount('#app')
