import Vue from 'vue'
import VueRouter from 'vue-router'
import {ability} from '../services/ability'
import { AbilityBuilder, Ability } from '@casl/ability'
const { can, cannot, build } = new AbilityBuilder(Ability)
import $eventHub from '../components/eventHub'

// products

Vue.use(VueRouter)

 const routes = [
 
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../components/Dashboard.vue'),
    meta: {
      auth: true
    }
  },
  {
      name: 'OfferDetails',
      path: '/products-on-offer',
      component: () => import('../components/products/OfferDetails.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'PickOfDay',
      path: '/pick-of-the-day',
      component: () => import('../components/products/PickOfDay.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'productDetails',
      path: '/product/:id/:name',
      component: () => import('../components/products/ProductDetails.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'productCategory',
      path: '/product-category/:name',
      component: () => import('../components/products/ProductCategory.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'productSubCategory',
      path: '/product-subcategory/:name',
      component: () => import('../components/products/ProductSubCategory.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'Payment',
      path: '/product-payment',
      component: () => import('../components/products/Payment.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'Checkout',
      path: '/checkout',
      component: () => import('../components/products/Checkout.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'ViewCart',
      path: '/shopping-cart',
      component: () => import('../components/products/ViewCart.vue'),
      meta: {
        auth: true
      }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (typeof to.matched[0]?.components.default === 'function') {
        $eventHub.$emit('asyncComponentLoading', to) // Start progress bar
    }
  next()
  // const loggedIn = localStorage.getItem('user')
  // if (to.matched.some(record => record.meta.auth) && !loggedIn) {
  //   next('/auth/login')
  //   return
  // }else {
  //   next()
  // }
  
})

router.beforeResolve((to, from, next) => {
    $eventHub.$emit('asyncComponentLoaded') // Stop progress bar
    next()
})
export default router
