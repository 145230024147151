import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {ability} from '../services/ability';
Vue.use(Vuex)

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/pos'
axios.defaults.baseURL = 'https://mshopbackend.tenwebtechnologies.com/api/pos'

var config = {
    headers: {'Access-Control-Allow-Origin': '*'}
}

let user = localStorage.getItem('user');
let cart = localStorage.getItem('cart');
let heldcart = window.localStorage.getItem('heldcart');
let cartCount = window.localStorage.getItem('cartCount');
let totalsale = window.localStorage.getItem('totalsale');
let totalprice = window.localStorage.getItem('totalprice');
let subtotalprice = window.localStorage.getItem('subtotalprice');
let discountItem = window.localStorage.getItem('discountItem');
let discountTotal = window.localStorage.getItem('discountTotal');


export const store =  new Vuex.Store({

	state: {
		user: user ? JSON.parse(user) : null,
		users: [],
		roles: [],
		userPermissions: [],
		stores: [],
		items: [],
		chart: null,
		reports: [],
		salesitemreport: [],
		salescategoryreport: [],
		categories: [],
		units: [],
		receipts: [],
		discounts: [],
		promotions: [],
		sliders: [],
		ads: [],
		offers: [],
		pickoftheday: [],

		cart: cart ? JSON.parse(cart) : [],
		heldcart: heldcart ? JSON.parse(heldcart) : [],
		cartCount: cartCount ? parseInt(cartCount) : 0,
		totalsale: totalsale ? totalsale : 0,
		totalprice: totalprice ? totalprice : 0,
		subtotalprice: subtotalprice ? subtotalprice : 0,
		discountItem: discountItem ? discountItem : 0,
		discountTotal: discountTotal ? discountTotal : 0,

		menus: [],
	   ratings: [],
		products: [],
	},
	mutations: {
		// account
		setUserData (state, userData) {

			state.user = userData
			localStorage.setItem('user', JSON.stringify(userData))
			axios.defaults.headers.common.Authorization = `Bearer ${state.user.token}`
		},

		clearUserData () {
			localStorage.removeItem('user')
			location.reload()
		},
		addDiscountTotal(state, discount) {

			state.discountTotal = discount
			window.localStorage.setItem('discountTotal', state.discountTotal);

			this.commit('totalPrice');
			
		},
		addToCart(state, item) {
			let found = state.cart.find(product => product.id == item.product.id);

			if (found) {
				// found.quantity ++;
				found.quantity = found.quantity + item.quantity;

				found.totalPrice = found.quantity * found.price;
				found.totalSale = found.quantity * found.cost;
			} else {
				state.cart.push(item.product);
				Vue.set(item.product, 'quantity', item.quantity);
				Vue.set(item.product, 'totalSale', item.product.cost);
				Vue.set(item.product, 'totalPrice', item.product.price);
				Vue.set(item.product, 'subTotalPrice', item.product.price);
			}

			state.cartCount++;

			this.commit('saveCart');

		},
		IncreaseToCart(state, item) {
			let found = state.cart.find(product => product.id == item.id);

			if (found) {
				found.quantity ++;
				found.totalPrice = found.quantity * found.price;
				found.totalSale = found.quantity * found.cost;
			} else {
				state.cart.push(item);
				Vue.set(item, 'quantity', 1);
				Vue.set(item, 'totalSale', item.cost);
				Vue.set(item, 'totalPrice', item.price);
				Vue.set(item, 'subTotalPrice', item.price);
			}

			state.cartCount++;

			this.commit('saveCart');

		},
		returnItem(state, item) {
			let index = state.heldcart.indexOf(item);

			if (index > -1) {
				let product = state.heldcart[index];
				state.cart = product
				state.heldcart.splice(index, 1);
			}
			window.localStorage.setItem('heldcart', JSON.stringify(state.heldcart))
			this.commit('saveCart');
		},
		removeFromCart(state, item) {
			let found = state.cart.find(product => product.id == item.id);

			if (found) {
				found.quantity --;
				found.totalPrice = found.quantity * found.price;
				found.totalSale = found.quantity * found.cost;
			} else {
				state.cart.push(item);

				Vue.set(item, 'quantity', 1);
				Vue.set(item, 'totalSale', item.cost);
				Vue.set(item, 'totalPrice', item.price);
				Vue.set(item, 'subTotalPrice', item.price);
			}

			state.cartCount--;

			if(found.quantity === 0) {
				this.commit('removeItem', item)
			}

			this.commit('saveCart');

		},
		removeItem(state, item) {
			let index = state.cart.indexOf(item);

			if (index > -1) {
				let product = state.cart[index];
				state.cartCount -= product.quantity;

				state.cart.splice(index, 1);
			}

			this.commit('saveCart');
		},
		saveCart(state) {
			window.localStorage.setItem('cart', JSON.stringify(state.cart));
			window.localStorage.setItem('cartCount', state.cartCount);

			this.commit('totalPrice');
		},
		totalPrice(state) {

			let total = 0;
			let subtotal = 0;
			let totalsales = 0;
			for (let item of state.cart) {
				subtotal += item.totalPrice;
				totalsales += item.totalSale;
			}
			state.totalsale = totalsales
			if(localStorage.getItem("discountTotal") != null){
				if(parseInt(localStorage.getItem("discountTotal")) != 0){

					let percentage = parseFloat(100.00) - parseFloat(localStorage.getItem("discountTotal"));
					let discount_subtotal = (parseFloat(subtotal) * percentage)/100;

					total = discount_subtotal;
					state.totalprice = total;

				}else {
					total = subtotal
				}
			    
			}else {
				total = subtotal
			}

			window.localStorage.setItem('totalsale', totalsales.toFixed(2));
			window.localStorage.setItem('totalprice', total.toFixed(2));
			window.localStorage.setItem('subtotalprice', subtotal.toFixed(2));
		},
		clearCart(state) {
			state.cart = []
			window.localStorage.setItem('cart', state.cart)
			window.localStorage.setItem('discountTotal', 0)
			window.localStorage.setItem('totalsale', 0)
			window.localStorage.setItem('totalprice', 0)
			window.localStorage.setItem('subtotalprice', 0)
		},
		holdCart(state) {
			state.heldcart.push(state.cart)
			window.localStorage.setItem('heldcart', JSON.stringify(state.heldcart))
			this.commit("clearCart")
		},
		discount(state, data) {

			let disc = {'discount': data.amount}

			if(data.type === true){

				localStorage.setItem('discount', JSON.stringify(disc));
				
			}else{

				localStorage.removeItem('discount')
			}
		},

		getUsers(state, data) {
			if(data.status === parseInt(200)) {
				state.users = data.users
				state.roles = data.roles
				state.userPermissions = data.userPermissions
			
				let formattedRules = [];
		        if (state.userPermissions.length > 0) {
		            formattedRules = state.userPermissions.map(perm => {
		                let formattedObj = {};
		                formattedObj.subject = 'all';
		                formattedObj.action = perm.substr(perm.indexOf(' ') + 1);
		                return formattedObj;
		            })
		        }
				ability.update(formattedRules)
			}
		},

		// pusher
		addpackage(state, data) {
			let found = state.packages.find(item => item.id == data.id);
			if (!found) {
				state.packages.unshift(data)

				return data
			}
		},

		productnew(state, data) {
			let found = state.items.find(item => item.id == data.id);
			if (!found) {
				state.items.unshift(data)

				return data
			}
		},
		productupdate(state, data) {
			let found = state.items.find(item => item.id == data.id);

			if (found) {
				found.id = data.id
		        found.item_name = data.item_name
		        found.category = data.category
		        found.unit = data.unit
		        found.image = data.image
		        found.quantity = data.quantity
		        found.category_id = data.category_id
		        found.unit_id = data.unit_id
		        found.price = data.price
		        found.cost = data.cost
		        found.sku = data.sku
		        found.barcode = data.barcode
		        found.squantity = data.squantity
		        found.refunded = data.refunded
		        found.current_stock = data.current_stock
			}
		},
		addstock(state, data) {
			let found = state.items.find(item => item.id == data.id);

			if (found) {
				found.id = data.id
		        found.item_name = data.item_name
		        found.category = data.category
		        found.unit = data.unit
		        found.image = data.image
		        found.quantity = data.quantity
		        found.category_id = data.category_id
		        found.unit_id = data.unit_id
		        found.price = data.price
		        found.cost = data.cost
		        found.sku = data.sku
		        found.barcode = data.barcode
		        found.squantity = data.squantity
		        found.refunded = data.refunded
		        found.current_stock = data.current_stockcd
			}
		},
		productdelete(state, data) {
			let index = state.items.findIndex(item => item.id == data.id);
			if (index > -1) {
				state.items.splice(index, 1);
			}
		},	

		getDashboard(state, data) {
            state.products = data.items
            state.promotions = data.promotions
            state.categories = data.categories
            state.sliders = data.sliders
            state.ads = data.ads
            state.offers = data.offers
            state.pickoftheday = data.pickoftheday

		}	
	},
	actions: {
		productnew ({ commit }, data) {
			commit('productnew', data)
		},
		productupdate ({ commit }, data) {
			commit('productupdate', data)
		},
		productdelete ({ commit }, data) {
			commit('productdelete', data)
		},
		addstock ({ commit }, data) {
			commit('addstock', data)
		},
		addpackage ({ commit }, data) {
			commit('addpackage', data)
		},


		getDashboard ({ commit }) {
			return axios
			.get('/dashboard/data')
			.then(({ data }) => {
				commit('getDashboard', data)
			})
		},
		// users
		getUsers ({ commit }) {
			return axios
			.get('/users')
			.then(({ data }) => {
				commit('getUsers', data)
			})
		},

		addDiscountTotal: (context, payload) => {
			context.commit('addDiscountTotal', payload)
		},
		addDiscountItem: (context, payload) => {
			context.commit('addDiscountItem', payload)
		},
		addToCart: (context, payload) => {
			context.commit('addToCart', payload)
		},
		IncreaseToCart: (context, payload) => {
			context.commit('IncreaseToCart', payload)
		},
		returnItem: (context, payload) => {
			context.commit('returnItem', payload)
		},
		removeFromCart: (context, payload) => {
			context.commit('removeFromCart', payload)
		},
		removeItem: (context, payload) => {
			context.commit('removeItem', payload)
		},
		clearCart: (context) => {
			context.commit('clearCart')
		},
		holdCart: (context) => {
			context.commit('holdCart')
		},

		// account
		login ({ commit }, credentials) {
			return axios
			.post('/customer-login', credentials)
			.then(({ data }) => {
				commit('setUserData', data)
			})
		},

		userdetails ({ commit }, data) {
			commit('setUserData', data)
		},

		signup ({ commit }, credentials) {
			return axios
			.post('/customer-signup', credentials)
			.then(({ data }) => {
				commit('setUserData', data)
			})
		},

		logout ({ commit, state }) {
			commit('clearUserData')
			// return axios
			// .post('/logout', {id: state.user.user.id})
			// .then(({ data }) => {
			// 	if(data.status == parseInt(200)) {
			// 		commit('clearUserData')
			// 	}
			// })
		}
	},
	getters : {
		isLogged: state => !!state.user
	},
	modules: {
	}
})

